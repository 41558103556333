import Image from "next/image";

import { makeApiImage } from "@/libs/clientConfig";
import PaPaySetUpIcon from "./Icon/PaPaySetUpIcon";
import CheckIcon from "./Icon/CheckIcon";
import ExclamationIcon from "./Icon/ExclamationIcon";

type Props = {
  data: any;
  imgWidthAndHeight: number;
};

const Profile = ({ data, imgWidthAndHeight }: Props) => {
  function getStyleByFirstLetter(firstName: string) {
    const firstLetter = firstName?.charAt(0).toLowerCase();
    const colors: { [key: string]: string } = {
      a: "#87A8BD",
      b: "#71A171",
      c: "#DF8C6A",
      d: "#D3AF73",
      e: "#445E62",
      f: "#B4A783",
      g: "#C391A5",
      h: "#8E8E8E",
      i: "#E79BB9",
      j: "#74BDBE",
      k: "#DA758A",
      l: "#84629A",
      m: "#699ACD",
      n: "#CDC077",
      o: "#CF6055",
      p: "#5A585B",
      q: "#8AA48A",
      r: "#D8A34E",
      s: "#7D7D7D",
      t: "#B79EA3",
      u: "#C6A598",
      v: "#DEAA85",
      w: "#666396",
      x: "#6D8783",
      y: "#4C939B",
      z: "#B74949",
    };
    return colors[firstLetter];
  }

  const isFullyVerified =
    data?.userId?.verification?.email &&
    data?.userId?.verification?.phone &&
    data?.userId?.verification?.bank &&
    data?.userId?.verification?.vouched;

  const isPartiallyVerified =
    data?.userId?.verification?.email &&
    data?.userId?.verification?.phone &&
    data?.userId?.verification?.vouched;

  return (
    <div
      style={{
        width: `${imgWidthAndHeight}px`,
        height: `${imgWidthAndHeight}px`,
      }}
      className="relative"
    >
      {data?.userId?.userDetails?.profileImage != null ? (
        <div>
          <Image
            width={imgWidthAndHeight}
            height={imgWidthAndHeight}
            className={`rounded-full`}
            style={{
              width: `${imgWidthAndHeight}px`,
              height: `${imgWidthAndHeight}px`,
            }}
            src={makeApiImage(data.userId.userDetails.profileImage)}
            alt="profile"
          />
          <div className=" absolute bottom-[0px] right-[0px]">
            {isFullyVerified ? (
              <PaPaySetUpIcon />
            ) : isPartiallyVerified ? (
              <CheckIcon size={16} />
            ) : (
              <ExclamationIcon size={16} />
            )}
          </div>
        </div>
      ) : (
        <div>
          {data?.userId?.userDetails?.firstName ||
          data?.userId?.userDetails?.lastName ? (
            <div
              style={{
                width: imgWidthAndHeight,
                height: imgWidthAndHeight,
                backgroundColor: getStyleByFirstLetter(
                  data.userId?.userDetails.firstName
                ),
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "large",
              }}
              className="w-full rounded-full"
            >
              {`${data?.userId?.userDetails?.firstName
                ?.charAt(0)
                .toUpperCase()}${data?.userId?.userDetails?.lastName
                ?.charAt(0)
                .toUpperCase()}`}
            </div>
          ) : (
            <Image
              width={imgWidthAndHeight}
              height={imgWidthAndHeight}
              className="w-full rounded-full"
              src={`/images/static/profile/defaultImg.png`}
              alt="profile"
            />
          )}
          <div className="absolute bottom-[0px] right-[0px] w-[16px] h-[16px]">
            {isFullyVerified ? (
              <PaPaySetUpIcon />
            ) : isPartiallyVerified ? (
              <CheckIcon size={16} />
            ) : (
              <ExclamationIcon size={16} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
